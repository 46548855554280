import React from "react"
import { Link } from "gatsby";

import SEO from "../components/SEO";

const NotFoundPage = () => (
    <div className="not-found px-4">
        <SEO 
            title="404"
            pathname="/404"
            description="Woops! The page you are requesting cannot be found"
        />
        <h1 className="mb-4">404</h1>
        <h3>The page you're looking for doesn't exist.</h3>
        <Link
            to="/"
            className="mt-6 px-2 py-1"
        >
            Take Me Home
        </Link>
    </div>
);

export default NotFoundPage;
